import React from "react";
import { Icon } from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { useStaticQuery, graphql } from "gatsby";

import Container from "@common/src/components/UI/Container";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import Image from "@common/src/components/Image";
import Link from "@common/src/components/Link";

import SectionWrapper, {
  Section,
  Content,
  Illustration,
} from './highlight.style.js';


const Highlight = () => {
  const Data = useStaticQuery(graphql`
    {
      allContentfulAktivitas(
        limit: 1
        sort: { fields: desc___references___updatedAt, order: ASC }
      ) {
        nodes {
          title
          shortdesc
          slug
          image {
            gatsbyImageData
          }
          id
        }
      }
    }
  `);

  const allContentfulAktivitas = Data.allContentfulAktivitas.nodes.filter(post => post.title !== 'placeholder_content')

  if(allContentfulAktivitas.length === 0) return null
  return (
    <SectionWrapper>
      <Container>
        <Section>
          {allContentfulAktivitas.map((post) => (
            <>
              <Illustration>
                <Fade left delay={100}>
                  <Image
                    src={post.image.gatsbyImageData.images.fallback.src}
                    alt="Illustration"
                  />
                </Fade>
              </Illustration>
              <Content>
                <Fade right delay={100}>
                  <Heading as="h2" content={post.title} />
                  <Text content={(post.shortdesc).substring(0,100) + " ..."} />
                  <Link className="explore" href={post.slug}>
                    BACA DETIL <Icon icon={chevronRight} />
                  </Link>
                </Fade>
              </Content>
            </>
          ))}
        </Section>
      </Container>
    </SectionWrapper> 
  );
};

export default Highlight;
