import React, { Fragment, useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import Image from "@common/src/components/Image";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import GlideCarousel from "@common/src/components/GlideCarousel";
import GlideSlide from "@common/src/components/GlideCarousel/glideSlide";
import {
  InteriorWrapper,
  SectionHeader,
  CircleLoader,
} from "../../styling-wrappers/interior.style";
import SectionWrapper, {
  TeamCard,
  ImageWrapper,
  TextWrapper,
  CarouselWrapper,
} from "./testimonial.style";
import Icon from "react-icons-kit";
import { instagram } from "react-icons-kit/fa/instagram";

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      testi: allContentfulHomeTestimonial(
        sort: { fields: contentful_id, order: ASC }
      ) {
        nodes {
          name
          designation
          image {
            gatsbyImageData
          }
          socialLink
        }
      }
    }
  `);
  const testi = data.testi.nodes.filter(t => t.name != "placeholder_content");;

  const [loading, setLoading] = useState(false);

  const glideOptions = {
    type: "carousel",
    perView: 3,
    gap: 50,
    breakpoints: {
      1200: {
        perView: 3,
        gap: 30,
      },
      767: {
        perView: 2,
        gap: 30,
      },
      480: {
        perView: 1,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <InteriorWrapper>
      <SectionWrapper id="team">
        <Container width="1170px">
          <Fade bottom>
            <SectionHeader>
              <Heading content={"Apa Kata Mereka"} />
            </SectionHeader>
          </Fade>
          <Fade bottom delay={30}>
            <CarouselWrapper>
              {loading ? testi.length > 0 && ((
                <GlideCarousel
                  carouselSelector="team_carousel"
                  options={glideOptions}
                  nextButton={<span className="next_arrow" />}
                  prevButton={<span className="prev_arrow" />}
                >
                  <Fragment>
                    {testi.map((testi, index) => (
                      <GlideSlide key={`team_key${index}`}>
                        <TeamCard className="team_card">
                          <ImageWrapper className="image_wrapper">
                            <Image
                              src={
                                testi.image.gatsbyImageData.images.fallback.src
                              }
                              alt={testi.name}
                              className="carousel_img"
                            />
                          </ImageWrapper>
                          <TextWrapper className="text_wrapper">
                            <div className="name_plate">
                              <Heading as="h3" content={testi.name} />
                              <Text content={testi.designation} />
                            </div>
                            <ul className="social_links">
                              {testi.socialLink.map((link, index) => {
                
                                return (
                                  <li key={`social_link__key${index}`}>
                                    <a
                                      href={link}
                                      aria-label="Check out our team member profile"
                                    >
                                      <Icon
                                        icon={instagram}
                                        className="price_list_icon"
                                        size={20}
                                      />
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </TextWrapper>
                        </TeamCard>
                      </GlideSlide>
                    ))}
                  </Fragment>
                </GlideCarousel>
              )) : (
                <CircleLoader className="alt">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </CircleLoader>
              )}
            </CarouselWrapper>
          </Fade>
        </Container>
      </SectionWrapper>
    </InteriorWrapper>
  );
};

export default Testimonials;
