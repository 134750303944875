import styled, { keyframes } from "styled-components";
import { rgba } from "polished";
import { themeGet } from "styled-system";

const SectionWrapper = styled.section`
  padding: 60px 0 75px;

  .glide {
    .glide__controls {
      margin-top: 30px;
    }

    .glide__controls > div,
    .glide__arrows > button {
      height: 18px;
      padding: 0;
      border: 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: transparent;

      .prev_arrow,
      .next_arrow {
        display: block;
        width: 24px;
        height: 2px;
        background-color: ${themeGet("colors.label", "#C6C6C6")};
        transition: width 0.3s ease;
        position: relative;
        @media only screen and (max-width: 667px) {
          width: 20px;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          border-radius: 4px;
          background-color: ${themeGet("colors.label", "#C6C6C6")};
          position: absolute;
          z-index: 1;
          transition: all 0.3s ease;
        }

        &.next_arrow {
          &::before {
            right: 0;
            transform: rotate(0);
            transform-origin: top right;
          }
          &::after {
            right: 0;
            transform: rotate(0);
            transform-origin: 14px 2px;
          }
        }

        &.prev_arrow {
          &::before {
            left: 0;
            transform: rotate(0);
            transform-origin: top left;
          }
          &::after {
            left: 0;
            transform: rotate(0);
            transform-origin: 0 2px;
          }
        }
      }

      .next_arrow {
        margin-left: 15px;
      }

      &:hover {
        > span {
          width: 45px;
          border-radius: 4px;
          background-color: ${themeGet("colors.primary", "#FDEF00")};
          @media only screen and (max-width: 667px) {
            width: 30px;
          }

          &::before,
          &::after {
            background-color: ${themeGet("colors.primary", "#FDEF00")};
          }

          &.prev_arrow {
            &::before {
              transform: rotate(-42deg);
            }
            &::after {
              transform: rotate(42deg);
            }
          }

          &.next_arrow {
            &::before {
              transform: rotate(42deg);
            }
            &::after {
              transform: rotate(-42deg);
            }
          }
        }
      }
    }
  }
`;

export const SectionHeading = styled.div`
  text-align: left;
  max-width: 1110px;
  margin: 0 auto;
  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 50px;
    letter-spacing: -0.5px;
    @media screen and (max-width: 480px) {
      line-height: 40px;
    }
  }
  p {
    font-size: 15px;
    line-height: 35px;
    color: ${themeGet("colors.headingColor")};
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 250px;

  #events-carousel,
  #article-carousel,
  #video-carousel,
  #glide {
    .glide__controls {
      margin: 0;
      position: absolute;
      top: -55px;
      right: 0;

      > div {
        > span {
          &.next_arrow {
            width: 45px;
            background-color: ${themeGet("colors.link", "#000000")};
            @media only screen and (max-width: 667px) {
              width: 30px;
            }

            &::before {
              background-color: ${themeGet("colors.link", "#000000")};
              transform: rotate(42deg);
            }

            &::after {
              transform: rotate(-42deg);
              background-color: ${themeGet("colors.link", "#000000")};
            }
          }
        }

        &:hover {
          > span {
            background-color: ${themeGet("colors.link", "#000000")};

            &::before,
            &::after {
              background-color: ${themeGet("colors.link", "#000000")};
            }

            &.next_arrow {
              width: 45px;
              &::before {
                transform: rotate(42deg);
              }
              &::after {
                transform: rotate(-42deg);
              }
            }
          }
        }
      }
    }
  }
`;

export const CircleLoader = styled.div`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  transform-origin: bottom center;

  .circle {
    background-color: ${themeGet("colors.primary", "#FDEF00")};
    border-radius: 50%;
    display: inline-block;
    margin: -9px;
    height: 40px;
    width: 40px;
    transform: scale(0);

    &:nth-of-type(2) {
      animation-delay: 0.75s;
      background-color: ${themeGet("colors.white", "#ffffff")};
    }
  }

  &.alt {
    .circle {
      &:nth-of-type(2) {
        background-color: ${themeGet("colors.heading", "#191919")};
      }
    }
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .blog_post {
    .explore {
      color: #b94a49;
    }
    p {
      font-size: 15px;
      line-height: 19px;
    }
    h3 {
      font-size: 16px;
      line-height: 25px;
      margin: 16px 20px 16px 0px;
      min-height: 50px;
    }
    .learn_more {
      display: flex;
      margin: 0;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: ${rgba("#343D48", 0.8)};
      img {
        margin-right: 8px;
      }
    }
  }
  .thumbnail {
    display: flex;
    img {
      max-width: 100%;
      border-radius: 10px;
    }
  }
`;

export default SectionWrapper;
