import React, { useState, useEffect, Fragment } from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";

import Container from "@common/src/components/UI/Container";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import BlogPost from "@common/src/components/BlogPost";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { Icon } from "react-icons-kit";
import Link from "@common/src/components/Link";

import SectionWrapper, {
  SectionHeading,
  NewsWrapper,
  CarouselWrapper,
  CircleLoader,
} from "./activity.style";

import GlideCarousel from "@common/src/components/GlideCarousel";
import GlideSlide from "@common/src/components/GlideCarousel/glideSlide";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const Video = ({ customContent, customTitle }) => {
  function getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  const Data = useStaticQuery(graphql`
    {
      allContentfulAktivitasVideo (sort: {fields: order, order: ASC}) {
        nodes {
          videoTitle
          videoThumbnail {
            gatsbyImageData
          }
          videoUrl
          id
        }
      }
    }
  `);

  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(null);

  const videoList = Data.allContentfulAktivitasVideo.nodes.filter(post => post.videoTitle != "placeholder_content")

  const glideOptions = {
    type: "slide",
    perView: 3,
    gap: 50,
    breakpoints: {
      1200: {
        perView: 3,
        gap: 30,
      },
      767: {
        perView: 2,
        gap: 30,
      },
      480: {
        perView: 1,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <SectionWrapper id="video">
      <Container>
        <Fade up delay={100}>
          <SectionHeading>
            <Heading as="h2" content={customTitle} />
            <Text content={customContent} />
          </SectionHeading>
        </Fade>

        <CarouselWrapper>
          {loading ? (videoList.length > 0 && (
            <GlideCarousel
              carouselSelector="video-carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {videoList.map((post, index) => (
                  <GlideSlide key={`news_key${index}`}>
                    <NewsWrapper>
                      <Fade up delay={100 * post.id} key={`news-${post.id}`}>
                        <BlogPost
                          thumbUrl={
                            post.videoThumbnail.gatsbyImageData.images.fallback
                              .src
                          }
                          title={post.videoTitle}
                          link={
                            <React.Fragment>
                              <Link
                                onClick={() => setOpen(index)}
                                className="explore"
                                href="javascript:void(0)"
                              >
                                Baca Detil <Icon icon={chevronRight} />
                              </Link>
                            </React.Fragment>
                          }
                        />
                      </Fade>
                    </NewsWrapper>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          )) : (
            <CircleLoader className="alt">
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselWrapper>
      </Container>
      {Data.allContentfulAktivitasVideo.nodes.map((post, index) => {
        return (
          typeof window !== "undefined" && (
            <ModalVideo
              channel="youtube"
              allowFullScreen
              isOpen={isOpen === index}
              videoId={getId(post.videoUrl)}
              onClose={() => setOpen(null)}
            />
          )
        );
      })}
    </SectionWrapper>
  );
};

export default Video;
