import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import Highlight from "../components/activity-components/Highlight/highlight-component";
import Testimonial from "../components/activity-components/Testimonial/testimonial-component";
import Article from "../components/activity-components/article-component";
import Events from "../components/activity-components/events-component";
import Video from "../components/activity-components/video-component";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Layout from "../components/layout";

const Aktivitas = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        title="Aktivitas"
        description="artikel, acara, video, dan juga review serta testimoni para artis yang sudah menggunakan produk GENME"
      />
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <PopupImage/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="Aktivitas" />
            </DrawerProvider>
          </Sticky>

          <Highlight />
          <Testimonial customTitle={"Apa Kata Mereka ?"} />
          <Article customTitle={"Artikel"} />
          <Events customTitle={"Acara"} />
          <Video customTitle={"Video"} />
          <Footer />
          <ButtonContact/>
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default Aktivitas;
