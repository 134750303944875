import React, { useState, useEffect, Fragment } from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";

import Container from "@common/src/components/UI/Container";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import BlogPost from "@common/src/components/BlogPost";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { Icon } from "react-icons-kit";
import Link from "@common/src/components/Link";

import SectionWrapper, {
  SectionHeading,
  NewsWrapper,
  CarouselWrapper,
  CircleLoader,
} from "./activity.style";

import GlideCarousel from "@common/src/components/GlideCarousel";
import GlideSlide from "@common/src/components/GlideCarousel/glideSlide";

const Article = ({ customContent, customTitle }) => {
  const Data = useStaticQuery(graphql`
    {
      allContentfulAktivitas(filter: { articleType: { eq: true } }) {
        nodes {
          image {
            gatsbyImageData
          }
          title
          shortdesc
          slug
          id
        }
      }
    }
  `);

  const allContentfulAktivitas = Data.allContentfulAktivitas.nodes.filter(post => post.title !== 'placeholder_content')

  const [loading, setLoading] = useState(false);

  const glideOptions = {
    type: "slide",
    perView: 3,
    gap: 50,
    breakpoints: {
      1200: {
        perView: 3,
        gap: 30,
      },
      767: {
        perView: 2,
        gap: 30,
      },
      480: {
        perView: 1,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <SectionWrapper id="news">
      <Container>
        <Fade up delay={100}>
          <SectionHeading>
            <Heading as="h2" content={customTitle} />
            <Text content={customContent} />
          </SectionHeading>
        </Fade>

        <CarouselWrapper>
          {loading ? (
            <GlideCarousel
              carouselSelector="article-carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {allContentfulAktivitas.map((post, index) => (
                  <GlideSlide key={`news_key${index}`}>
                    <NewsWrapper>
                      <Fade up delay={100 * post.id} key={`news-${post.id}`}>
                        <BlogPost
                          thumbUrl={
                            post.image.gatsbyImageData.images.fallback.src
                          }
                          title={post.title}
                          shortDesc={(post.shortdesc).substring(0,80) + " ..."}
                          link={
                            <React.Fragment>
                              <Link className="explore" href={post.slug}>
                                Baca Detil <Icon icon={chevronRight} />
                              </Link>
                            </React.Fragment>
                          }
                        />
                      </Fade>
                    </NewsWrapper>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          ) : (
            <CircleLoader className="alt">
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Article;
